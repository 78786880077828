import Group_001 from "../../../public/Group_001.png";
import icon from "../../../public/icon.png";
import icon_2 from "../../../public/icon_2.png";
import twitter from "../../../public/twitter 1.png";

export const links = [
  {
    link: "#",
    img: Group_001,
    active: true,
    only_mobile: false,
  },
  // {
  //   link: "#",
  //   img: icon,
  //   active: false,
  //   only_mobile: false,
  // },
  // {
  //   link: "#",
  //   img: icon_2,
  //   active: false,
  //   only_mobile: false,
  // },
  {
    link: "#",
    img: twitter,
    active: false,
    only_mobile: true,
  },
];
